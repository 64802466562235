// AccountSettingsPage.js
import React, { useState } from 'react';
import { Collapse, Input, Button, Form, message } from 'antd';
import { LockFilled, UserOutlined, BankFilled } from '@ant-design/icons';
import axios from 'axios';
import styled from 'styled-components';
import { StyledHeader } from '../styles/shared_components';
import { useAuthUser } from '../components/AuthUserContext';
import DEV_REQUESTS_PREFIX from '../utils/config';


const { Panel } = Collapse;

const StyledPage = styled.div`
  width: 60%;
  margin: auto;
`;

const StyledPanel = styled(Panel)`
  .ant-collapse-header {
    font-weight: bold;
  }
`;

const PanelContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adjust the spacing as needed */
  align-items: center;
`;


const AccountSettingsPage = () => {
  const { user, token, updateUser } = useAuthUser(); // Assuming there's a logout function in your context
  const [newName, setNewName] = useState('');
  const [newInstitution, setNewInstitution] = useState('');
  const [form] = Form.useForm();

  // Custom validator for confirm password
  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('newPassword') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('The two passwords that you entered do not match!'));
    },
  });

  const handlePasswordChange = async (values) => {
    try {
      const { currentPassword, newPassword, confirmNewPassword } = values;
      // Add your logic to send these values to the server
      const response = await axios.post(`${DEV_REQUESTS_PREFIX}/api/set-new-password`, {
        current_password: currentPassword,
        new_password: newPassword,
        confirm_new_password: confirmNewPassword
      },
      {
        headers: {
            Authorization: `Bearer ${token}`,
          },
        
    });
      // Handle the response, e.g., show a success message or handle errors
      if (response.data.success) {
        // Assuming the server sends a success flag
        //message.success('Password updated successfully');
        // Optionally reset the form here
        message.success('Password updated successfully.');
        form.resetFields();
      } else {
        // Handle any errors, e.g., incorrect current password
        //message.error(response.data.message || 'Error updating password');
      }
    } catch (error) {
      // Handle network or server errors
      console.error('Error:', error);
      message.error('An error occurred while updating the password');
    }
  };

  const handleNameChange = async () => {
    if (newName === "") {
       message.error('No new name was provided!');
    } else {
        const response = await axios.post(`${DEV_REQUESTS_PREFIX}/api/set-new-name`, { new_name: newName }, {
            headers: {
                Authorization: `Bearer ${token}`,
              },
        });

        if (response.data.success) {
            message.success('Name updated successfully.');
            updateUser({ full_name: newName });
        }
    }
    
  };

  const handleInstitutionChange = async () => {
    if (newInstitution === "") {
        message.error('No new institution was provided!');
    }
    else {
        const response = await axios.post(`${DEV_REQUESTS_PREFIX}/api/set-new-institution`, { new_institution: newInstitution }, {
            headers: {
                Authorization: `Bearer ${token}`,
              },
        });

        if (response.data.success) {
            message.success('Institution updated successfully.');
            updateUser({ institution: newInstitution });
        }
    }
  };

  return (
    <div>
    <StyledPage>
      <StyledHeader>Edit Account Setting</StyledHeader>
      <Collapse>
        <StyledPanel header={`Password: ********`} key="1">
            <PanelContent>
        <Form
              form={form}
              layout="vertical"
              onFinish={handlePasswordChange}
            >
              <Form.Item
                name="currentPassword"
                rules={[{ required: true, message: 'Please input your current password!' }]}
              >
                <Input
                  type="password"
                  placeholder="Current Password"
                  prefix={<LockFilled/>}
                />
              </Form.Item>
              <Form.Item
                name="newPassword"
                rules={[{ required: true, message: 'Please input your new password!' }]}
              >
                <Input
                  type="password"
                  placeholder="New Password"
                  prefix={<LockFilled/>}
                />
              </Form.Item>
              <Form.Item
                name="confirmNewPassword"
                dependencies={['newPassword']}
                rules={[
                  { required: true, message: 'Please confirm your new password!' },
                  validateConfirmPassword
                ]}
              >
                <Input
                  type="password"
                  placeholder="Confirm New Password"
                  prefix={<LockFilled/>}
                />
              </Form.Item>
              <Button type='primary' htmlType="submit">Set New Password</Button>
            </Form>
            </PanelContent>
        </StyledPanel>
        <StyledPanel header={`Name: ${user.full_name}`} key="2">
        <PanelContent>
          <Input
            placeholder="New Full Name"
            style={{ width: '40%' }}
            prefix={<UserOutlined/>}
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
          <Button type='primary' onClick={handleNameChange}>Set New Name</Button>
          </ PanelContent>
        </StyledPanel>
        <StyledPanel header={`Institution: ${user.institution}`} key="3">
        <PanelContent>
          <Input
            placeholder="Institution/Company"
            style={{ width: '40%' }}
            prefix={<BankFilled/>}
            value={newInstitution}
            onChange={(e) => setNewInstitution(e.target.value)}
          />
          <Button type='primary' onClick={handleInstitutionChange}>Set New Institution</Button>
          </PanelContent>
        </StyledPanel>
      </Collapse>
    </StyledPage>
    </div>
  );
};

export default AccountSettingsPage;
