import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Button, Modal, Input, List, Empty, message, Select, Form, Switch } from 'antd';
import { SendOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import styled from 'styled-components';
import { useAuthUser } from '../components/AuthUserContext';
import { StyledHeader, StyledParagraph, ButtonsContainer } from "../styles/shared_components";
import DEV_REQUESTS_PREFIX from '../utils/config';



const StyledForm = styled.form`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 0 10px;
`;

const ActionsContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 10px 0;
`;

const ListContainer = styled.div`
  height: 30vh;
  overflow-y: auto;
  border: 1px solid #e0e0e0; /* 1px thick light gray border */
  border-radius: 20px;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Header = styled.h3`
  text-align: center;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start; // Align items at the start of the container
  width: 100%;
`;

const BoldFormItem = styled(Form.Item)`
.ant-form-item-control {
  width: 100%;
}
.ant-form-item-label {
  text-align: left;
}
.ant-form-item-label > label {
  display: block; // Ensure the label is a block element to appear on its own line
  margin-bottom: 8px; // Add some space between the label and the form control
  font-weight: bold; // Keep the bold styling for the label
}
`;

const ModalInput = styled(Input)`
  width: 80%;
`;

const TooltipContent = ({ link, prefixText }) => (
  <span>
    {prefixText && (
      <>
        {prefixText}
        <br />
        <br />
      </>
    )} For details, see the instructions on the
    <a href={link} target="_blank" rel="noopener noreferrer"> Home Page</a>.
  </span>
);


const { Option } = Select;


const TaskCreationPage = () => {
  const [formValues, setFormValues] = useState({ nonReversed: [], reversed: [] });
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedItem, setSelectedItem] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [modalValue, setModalValue] = useState('');
  const [briefQIAT, setBriefQIAT] = useState(false);
  const [taskName, setTaskName] = useState('');
  const {token} = useAuthUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { editMode, task } = location.state || { editMode: false, task: null };
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false);
  const [platform, setPlatform] = useState('qualtrics');
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const [participantId, setParticipantId] = useState('');
  const [completionRedirectUrl, setCompletionRedirectUrl] = useState('');
  const [isActivated, setIsActivated] = useState(false);
  const [language, setLanguage] = useState('en');

  useEffect(() => {
    if (editMode && task) {
      setTaskName(task.name);
      setFormValues({ nonReversed: task.non_reversed_sentences, reversed: task.reversed_sentences });
      setBriefQIAT(task.is_brief);
      setPlatform(task.platform);
      setIsActivated(task.pavlovia_active);
      setParticipantId(task.redirect_id_param);
      setCompletionRedirectUrl(task.redirect_url);
      setShowAdditionalFields(task.platform === 'other');
      setLanguage(task.language);
    }
  }, [editMode, task]);


  const editSentence = (value, category, index) => {
    if (value.trim()) {
      const newValues = { ...formValues };
      newValues[category][index] = value;
      setFormValues(newValues);
      setSelectedItem(value);
    } else {
      removeSentence(category, index);
    }
  };

  const handleEdit = (item) => {
    setModalValue(item);
    setModalVisible(true)
  }

  const removeSentence = (category, index) => {
    const newValues = { ...formValues };
    newValues[category].splice(index, 1);
    setFormValues(newValues);
    if (index) {
      setSelectedIndex(index-1);
      setSelectedItem(formValues[category][index])
    }
    else {
      setSelectedCategory(null);
      setSelectedIndex(null);
      setSelectedItem(null);
    }
  };

  const handleKeyPressModal = (event) => {
    if (event.key === 'Enter') {
      handleModalDone();
    }
  };

  const handleModalDone = () => {
    editSentence(modalValue, selectedCategory, selectedIndex);
    setModalVisible(false);
    setModalValue('');
  };


  const handleModalCancel = () => {
    setModalValue('');
    setModalVisible(false);
  }

  const handleAddClick = (category) => {
    setModalValue('');
    setSelectedCategory(category);
    setSelectedIndex(formValues[category].length);
    setModalVisible(true);
  };

  const warnBeforeLeaving = (event) => {
    if (editMode) {
      const message = "Are you sure you want to leave? Changes you made may not be saved.";
      event.returnValue = message; // Standard for most browsers
      return message; // For some older browsers
    }
  };

  useEffect(() => {
    window.addEventListener('beforeunload', warnBeforeLeaving);
    return () => {
      window.removeEventListener('beforeunload', warnBeforeLeaving);
    };
  }, [editMode]);

  const confirmCancel = () => {
    setShowCancelConfirmation(false);
    navigate('/my-tasks'); // Navigate back to tasks list
  };


  const handlePreSubmit = () => {
    setShowSubmitConfirmation(true);
  };
  
  const confirmSubmit = () => {
    setShowSubmitConfirmation(false);
    submitPage();
  };
  
  const cancelSubmit = () => {
    setShowSubmitConfirmation(false);
  };

  const handleSubmit = async (e) => {
    if (editMode) {
      handlePreSubmit();
    } else {
      submitPage(e);
    }
  };

  const submitPage = async (e) => {
    let isActivatedSubmit = isActivated;

    if (e) e.preventDefault();

    if (formValues.nonReversed.length < 4 || formValues.reversed.length < 4) {
      message.error('Please fill each category with a minimum of 4 sentences.');
      return;
    }
    if (formValues.nonReversed.length > 6 || formValues.reversed.length > 6) {
      message.error('Please fill each category with a maximum of 6 sentences.');
      return;
    }
    if (formValues.nonReversed.length !== formValues.reversed.length) {
      message.error('Please ensure that both categories contain an equal number of sentences.');
      return;
    }
    if (!taskName.trim()) {
      message.error('Please provide a task name.');
      return;
    }

    // if platfrom is qualtics, then the task is activated by default
    if (platform === 'qualtrics') {
      setIsActivated(true);
      isActivatedSubmit = true;
    }

    // Prepare the data to be sent to the API
    let taskData = {
      name: taskName,
      reversed_sentences: formValues.reversed,
      non_reversed_sentences: formValues.nonReversed,
      is_brief: briefQIAT,
      platform: platform,
      participant_id: participantId,
      completion_redirect_url: completionRedirectUrl,
      active: isActivatedSubmit,
      language: language,
    };

    try {
      if (editMode && platform === 'qualtrics') {

        // Delete the old task
        await axios.post(`${DEV_REQUESTS_PREFIX}/api/delete_task`, { task_id: task.id }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      }

      else if (editMode && platform !== 'qualtrics') {
        // add task id to the task data
        taskData['task_id'] = task.id;

        const response = await axios.post(`${DEV_REQUESTS_PREFIX}/api/update_non_active_task`, taskData, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });
      if (response.data.success) {
        message.success('Task edited successfully.');
        setTimeout(() => { navigate('/my-tasks'); }, 3000);
      } else {
        message.error('Task edition failed.');
      }
      return;
    }

      
      // make new task
      const response = await axios.post(`${DEV_REQUESTS_PREFIX}/api/new_task`, taskData, {
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the authorization header
        },
      });

      // Handle response here
      if (response.data.success) {
        if (editMode) {
          message.success('Task edited successfully.');
        } else {
          message.success('Task created successfully.');
        }
        // Redirect to TaskIntegrationInstructions page with task data
        if (isActivated) {
          setTimeout(() => { navigate('/my-tasks/instructions', { state: { task: response.data.task } }); }, 3000);
        }
        else {
          setTimeout(() => { navigate('/my-tasks'); }, 3000);
        }
      } else {
        if (editMode) {
          message.error('Task edition failed.');
        } else {
          message.error('Task creation failed.');
        }
        
      }
    } catch (error) {
      // Handle error here
      if (editMode) {
        message.error(error.response?.data?.detail || 'An error occurred while editing the task.');
      } else {
        message.error(error.response?.data?.detail || 'An error occurred while creating the task.');
      }
      message.error();
    }
  };

  const handlePlatformChange = (value) => {
    setPlatform(value);
    setShowAdditionalFields(value === 'other'); // Show additional fields if "other" is selected
  };

  return (
    <div>
      <StyledHeader>Configure a qIAT Task</StyledHeader>
      <StyledParagraph>
      Please provide a minimum of 4 and a maximum of 6 sentences for each category. Ensure that every category contains the same number of sentences. Categories will be randomly assigned to Type 1 and Type 2 across participants. Select 'Brief qIAT' for the shortened version of the task. After completing and submitting the task, you will receive detailed instructions for integrating it into your experiment.
      </StyledParagraph>
      <StyledForm onSubmit={handleSubmit}>
      <FlexContainer>
  <div layout="vertical" style={{ marginRight: '5%', width: '35%'}}> {/* Adjust marginRight as needed */}
    <BoldFormItem label="Task Name" tooltip="For internal use only - won't be shown to participants">
      <Input
        placeholder="e.g., Extraversion qIAT"
        value={taskName}
        onChange={(e) => setTaskName(e.target.value)}
        disabled={editMode}
      />
    </BoldFormItem>
  </div>

  
  <div layout="vertical" style={{ marginRight: '5%', width: '35%' }}>
    <BoldFormItem 
    label="Study Platform" 
    tooltip={<TooltipContent
      link="/#taskCreations/generalInstructions"
      prefixText="Determines the platform where the task will be hosted."
      />}
    >
      <Select value={platform} onChange={handlePlatformChange} disabled={editMode}>
        <Option value="qualtrics">Qualtrics</Option>
        <Option value="other">Other</Option>
      </Select>
    </BoldFormItem>
  </div>
 
  <div style={{ width: '20%' }}>
  <BoldFormItem 
  label="Brief qIAT" 
  tooltip={<TooltipContent
    link="/#qIATDescription/briefqIAT"
    prefixText="Select 'Brief qIAT' for an shortened version of the task."
    />}

  >
              <Switch
              checked={briefQIAT} 
              //  on change set the value of isActivated to the value of the switch and prints it out
               onChange={(e) => setBriefQIAT(e) } 
               checkedChildren={<CheckOutlined />}
               unCheckedChildren={<CloseOutlined />}
               />
            </BoldFormItem>
  </div>
</FlexContainer>
        {showAdditionalFields && (
        <FlexContainer>
          <div layout="vertical" style={{ marginRight: '5%', width: '35%'}}> {/* Adjust marginRight as needed */}
           <BoldFormItem 
           label="Completion Participant ID URL Parameter" 
           tooltip={<TooltipContent
            link="/#taskCreations/multiPlatformMode"
            prefixText=""
            />}
           >
              <Input
                placeholder="e.g., pid"
                value={participantId}
                onChange={(e) => setParticipantId(e.target.value)}
              />
            </BoldFormItem>
          </div>
          <div layout="vertical" style={{ marginRight: '5%', width: '35%'}}> {/* Adjust marginRight as needed */}
            <BoldFormItem 
            label="Completion Redirect URL" 
            tooltip={<TooltipContent
              link="/#taskCreations/multiPlatformMode"
              prefixText=""
              />}
            >
              <Input
                placeholder="e.g., https://www.example.com"
                value={completionRedirectUrl}
                onChange={(e) => setCompletionRedirectUrl(e.target.value)}
              />
            </BoldFormItem>
          </div>
          <div style={{ width: '20%' }}>
            <BoldFormItem label="Active" tooltip="Check to make the task active and available for execution. Inactive tasks can be edited, but once active, a task cannot be edited or made inactive again.">
              <Switch
              checked={isActivated} 
              //  on change set the value of isActivated to the value of the switch and prints it out
               onChange={(e) => setIsActivated(e) } 
               checkedChildren={<CheckOutlined />}
               unCheckedChildren={<CloseOutlined />}
               />
            </BoldFormItem>
          </div>
        </FlexContainer>
        )}
        <Container>
          {['nonReversed', 'reversed'].map((category) => (
            <CategoryContainer key={category}>
              <Header>
                {category === 'nonReversed' ? 'Non-Reversed Category' : 'Reversed Category'}
              </Header>
              <ActionsContainer>
                <Button size="small" onClick={() => handleAddClick(category)}>
                  Add
                </Button>
                <Button size="small" onClick={() => handleEdit(selectedItem)} disabled={selectedCategory !== category}>
                  Edit
                </Button>
                <Button size="small" onClick={() => removeSentence(selectedCategory, selectedIndex)} disabled={selectedCategory !== category}>
                  Remove
                </Button>
              </ActionsContainer>
              <ListContainer>
                <List
                  dataSource={formValues[category]}
                  size="small"
                  locale={{ emptyText: <Empty description="No sentences yet" /> }}
                  renderItem={(item, index) => (
                    <List.Item
                      style={{
                        cursor: 'pointer',
                        backgroundColor: selectedIndex === index && selectedCategory === category ? '#1890ff' : 'transparent',
                        color: selectedIndex === index && selectedCategory === category ? '#fff' : 'inherit',
                      }}
                      onClick={() => {
                        setSelectedCategory(category);
                        setSelectedIndex(index);
                        setSelectedItem(item);
                      }}
                    >
                      {item}
                    </List.Item>
                  )}
                />
              </ListContainer>
            </CategoryContainer>
          ))}
        </Container>

        <ButtonsContainer>
          {editMode && (
          <Button type="link" onClick={() => {setShowCancelConfirmation(true);}}>
            Cancel
          </Button>
          )}
          <div layout="horizontal">
          <BoldFormItem label="Language" tooltip="Please ensure that the selected language matches the language of the provided sentences.">
            <Select 
            value={language} 
            onChange={(e) => setLanguage(e)} 
            >
              <Option value="en">English</Option>
              <Option value="heb_male">Hebrew (male)</Option>
              <Option value="heb_female">Hebrew (female)</Option>
              <Option value="heb_both">Hebrew (male/female)</Option>
              <Option value="arb">Arabic</Option>
            </Select>
          </BoldFormItem> 
          </div>            
          <Button type="primary" onClick={handleSubmit}>
            <SendOutlined /> Submit
          </Button>
        </ButtonsContainer>
      </StyledForm>
      <Modal
        open={modalVisible}
        onCancel={handleModalCancel}
        footer={[<Button key="done" size="small" onClick={handleModalDone}>Done</Button>]}
      >
        <ModalInput value={modalValue} onChange={(e) => setModalValue(e.target.value)}  onKeyPress={handleKeyPressModal} />
      </Modal>
      <Modal
        title="Confirm Cancellation"
        open={showCancelConfirmation}
        onOk={confirmCancel}
        onCancel={() => {setShowCancelConfirmation(false);}}
      >
        <p>Are you sure you want to exit? Nothing will be changed.</p>
      </Modal>
      <Modal
        title="Confirm Submission"
        open={showSubmitConfirmation}
        onOk={confirmSubmit}
        onCancel={cancelSubmit}
      >
        <p>Are you sure you want to submit the changes?</p>
    </Modal>
    </div>
  );
};

export default TaskCreationPage;
