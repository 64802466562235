import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Alert, Checkbox, Modal } from 'antd';
import axios from 'axios';
import { useAuthUser } from '../components/AuthUserContext';
import { MailFilled, LockFilled } from '@ant-design/icons';
import DEV_REQUESTS_PREFIX from '../utils/config';



const Login = () => {
  const [error, setError] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [unverifiedEmail, setUnverifiedEmail] = useState('');
  const navigate = useNavigate();
  const { addTokenUser } = useAuthUser(); // Destructure the addToken function from useAuth

  useEffect(() => {
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('token');
    localStorage.removeItem('tokenIssuedAt');
    // Any other cleanup if necessary
  }, []);

  const showModal = (email) => {
    setUnverifiedEmail(email);
    setIsModalVisible(true);
  };

  const handleResendVerification = async () => {
    try {
      // Call your API to resend the verification email
      await axios.post(`${DEV_REQUESTS_PREFIX}/api/resend_verification`, { email: unverifiedEmail });
      Modal.success({
        title: 'Verification Email Sent',
        content: `A verification email has been sent to ${unverifiedEmail}. Please check your inbox.`,
      });
    } catch (error) {
      Modal.error({
        title: 'Error',
        content: 'There was an issue sending the verification email. Please try again later.',
      });
    }
    setIsModalVisible(false);
  };

  const onFinish = async (values) => {
    try {
      const response = await axios.post(`${DEV_REQUESTS_PREFIX}/api/login`, values);

      // Save the token and user after successful login
      if (response.data && response.data.access_token && response.data.user) {
        localStorage.setItem('refresh_token', response.data.refresh_token);
        addTokenUser(response.data.access_token, response.data.refresh_token, response.data.user);
      }

      navigate('/my-tasks'); // needs to be changed to my-tasks or stay like this - Roee
    } catch (e) {
      if (e.response && e.response.status === 401) {
        if (e.response.data.detail === "Email not verified") {
          showModal(values.email);
        } else if (e.response.data.detail === "No such Email address") {
          setError(<span>
            Email does not exist. <Link to="/signup">Sign up?</Link>
          </span>);
        } else {
          setError(<span>
            Wrong password.   
            <Link to="/reset-password">  Reset password?</Link>
          </span>);
        }
      } else {
        setError('An error has occured. Please try again.');
      }
    }
  };

  return (
    <div className="login-container">
      {error && <Alert message={error} type="error" showIcon />}
      <Form
        style={{textAlign: 'center'}}
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <h2>Login</h2>
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your Email!' }]}
        >
          <Input 
          style={{ width: '40%' }}
          prefix= {<MailFilled />}
          placeholder="Email" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please input your Password!' }]}
        >
          <Input
            style={{ width: '40%' }}
            prefix={<LockFilled/>}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item>
        <Form.Item name="remember" valuePropName="checked" style={{ float: 'left', marginLeft: '31%' }}>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <a className="login-form-forgot" href="/reset-password" style={{ float: 'right', marginRight: '31%' }}>
          Forgot password?
        </a>
      </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button">
            Log in
          </Button>
        </Form.Item>
        <Form.Item>
        Or <Link to="/signup">register now!</Link>
        </Form.Item>
      </Form>
      <Modal
        title="Verify Your Email Address"
        visible={isModalVisible}
        onOk={handleResendVerification}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="resend" type="primary" onClick={handleResendVerification}>
            Resend Verification Email
          </Button>,
        ]}
      >
        <p>It looks like you haven't verified your email address yet. Please check your inbox for the verification email. If you can't find it, it might be in your SPAM folder.</p>
        <p>Can't find the email? We can resend it to you.</p>
      </Modal>
    </div>
  );
};

export default Login;
