// src/styles/themes.js
export const lightGreenTheme = {
    sides: '#b2f2bb', // light green
    cont: '#f2f2f2', // light grey for contrast
    navbar: '#f2f2f2', // not used originally in this theme
    // add more colors as needed
  };
  export const bluishGrayTheme = {
    sides: '#99B1C1', // Light Bluish Gray
    cont: '#f2f2f2', // light grey for contrast
    navbar: '#f2f2f2', // light grey
    // add more colors as needed
  };
  export const ColorField = {
    sides: '#5C5E74', // dark blue
    cont: '#F7FAFC', // white for contrast
    navbar: '#D1945D', // light brown
    // add more colors as needed
  };
  export const FloraTones = {
    sides: '#BDB0B8', // light purple
    cont: '#F7FAFC', // white for contrast
    navbar: '#FAB76E', // orange
    // add more colors as needed
  };
  export const Mood = {
    sides: '#B5D6DC', // light blue
    cont: '#F7FAFC', // white for contrast
    navbar: '#786B94', // light purple
    // add more colors as needed
  };
  export const Nature = {
    sides: '#FBCCC2', // light red
    cont: '#f2f2f2', // light grey for contrast
    navbar: '#718BC0', // light blue
    // add more colors as needed
  };
  export const Serve = {
    sides: '#CDDAEB', // light red
    cont: '#F7FAFC', // white for contrast
    navbar: '#93417A', // light purple
    // add more colors as needed
  };
  export const Mineral = {
    sides: '#D1EBE5', // light green
    cont: '#F7FAFC', // light grey for contrast
    navbar: '#745F83', // purple
    // add more colors as needed
  };
  export const ChilledTones = {
    sides: '#F0EEE9', // cream
    cont: '#F7FAFC', // white for contrast
    navbar: '#8C718C', // purple
    // add more colors as needed
  };
  export const ChilledCrystal = {
    sides: '#F0EEE9', // cream
    cont: '#F7FAFC', // white for contrast
    navbar: '#B5936B', // purple
    // add more colors as needed
  };
  export const MineralTones = {
    sides: '#E7E5EB', // marble
    cont: '#F7FAFC', // white for contrast
    navbar: '#B7676A', // bordo
    // add more colors as needed
  };
  export const Imagination = {
    sides: '#E6ECF2', // cream
    cont: '#F7FAFC', // white for contrast
    navbar: '#FCD6B3', // purple
    // add more colors as needed
  };
    
  