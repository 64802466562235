import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Modal, List, message, Typography, Empty, Popconfirm } from 'antd';
import { ArrowRightOutlined, DownloadOutlined, ArrowUpOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useAuthUser } from '../components/AuthUserContext';
import { useNavigate } from 'react-router-dom';
import DEV_REQUESTS_PREFIX from '../utils/config';
import styled from 'styled-components';

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: space-between; /* Distribute items evenly */
  align-items: center;
  height: 100%; /* Full height of the parent container */

  & > button {
    width: 100%; /* Make all buttons have the same width */
  }

  & > button:first-child {
    margin-top: 25%; /* Position the first button at 25% of the container's height */
  }

  & > button:last-child {
    margin-top: 25%; /* Position the second button at 75% of the container's height */
  }
`;

const ActiveStatus = styled.span`
  color: green;
  font-weight: bold;
`;

const NotActiveStatus = styled.span`
  color: red;
  font-weight: bold;
`;


const { Link } = Typography;

const MyQiatTasks = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [currentEditingTask, setCurrentEditingTask] = useState(null);


  const navigate = useNavigate();
  const {token} = useAuthUser();

  useEffect(() => {
    setLoading(true);
    axios.get(`${DEV_REQUESTS_PREFIX}/api/get_tasks`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => {
      setLoading(false);
      setTasks(response.data);
    })
    .catch(error => {
      setLoading(false);
      message.error('Something went wrong, please try again');
    });
  }, [token]);


  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <>
        <strong>{text}</strong>
        <br />
        {record.is_brief ? '(Brief)' : ''}
        </>
      ),
    },
    {
      title: 'Platform',
      dataIndex: 'platform',
      key: 'platform',
      render: (text, record) => (
        <>
          {text.charAt(0).toUpperCase() + text.slice(1)} {/* Capitalize the first letter */}
          <br />
          {text === 'qualtrics' ? (
            <ActiveStatus>(Active)</ActiveStatus>
          ) : record.pavlovia_active ? (
            <ActiveStatus>(Active)</ActiveStatus>
          ) : (
            <NotActiveStatus>(Not Active)</NotActiveStatus>
          )}
        </>
      ),
    },
    {
      title: 'Date Created',
      dataIndex: 'created_date',
      key: 'created_date',
      render: date => <span>{moment(date).format('DD/MM/YYYY')}</span>,
    },
    {
      title: 'Non-Reversed Sentences',
      key: 'non_reversed_sentences',
      render: (_, record) => (
        <Button onClick={() => showModal(record.non_reversed_sentences, 'Non-Reversed Sentences')}>
          <ArrowUpOutlined /> Show
        </Button>
      ),
    },
    {
      title: 'Reversed Sentences',
      key: 'reversed_sentences',
      render: (_, record) => (
        <Button onClick={() => showModal(record.reversed_sentences, 'Reversed Sentences')}>
          <ArrowUpOutlined /> Show
        </Button>
      ),
    },
    {
      title: 'Uploading the Task',
      key: 'uploading_the_task',
      render: (_, record) => (
        (record.platform === 'qualtrics') || (record.pavlovia_active) ? (
          <Button onClick={() => navigate('/my-tasks/instructions', { state: { task: record } })}>
          <ArrowRightOutlined /> Go
          </Button>
        ) : (
          <span><NotActiveStatus>Task has to be active.</NotActiveStatus></span>
        )
      ),
    },
    {
      title: 'Analysis',
      key: 'analysis',
      render: (text, record) => (
        <div>
          {record.task_results_url === "processing" ? (
            <div>Analysis is processing. You'll receive an email when it's ready.</div>
          ) :
          record.task_results_url_count === 0 ? (
            <>
              <div>No analysis available yet.</div>
              <Link href="/analysis">Go to analysis page</Link>
            </>
          ) : (
            <>
              <Button href={record.task_results_url} target="_blank">
                <DownloadOutlined /> Download
              </Button>
              <div>
                Last Analysis:
                <br />
                {moment(record.last_analyzed_date).format('DD/MM/YYYY')}
              </div>
            </>
          )}
        </div>
      ),
    },
    {
      title: 'Task\'s Actions',
      key: 'actions',
      render: (text, record) => (
        <ActionsContainer>
          {(record.platform === 'qualtrics' || !record.pavlovia_active) && (
            <Button size='small' onClick={() => { setCurrentEditingTask(record); setIsEditModalVisible(true); }}>
              <EditOutlined /> edit
            </Button>
          )}
          <Popconfirm
            title="Are you sure to delete this task?"
            onConfirm={() => deleteTask(record.id)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger='true' type='primary' size='small'>
              <DeleteOutlined /> delete
            </Button>
          </Popconfirm>
        </ActionsContainer>
      ),
    }
  ];

  const showModal = (content, title) => {
    setModalContent(content);
    setModalTitle(title);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleEdit = () => {
    setIsEditModalVisible(false);
    if (currentEditingTask) {
      navigate('/new-task', { state: { editMode: true, task: currentEditingTask } });
      setCurrentEditingTask(null); // Reset the current editing task
    }
  };

  const deleteTask = (taskId) => {
    axios.post(`${DEV_REQUESTS_PREFIX}/api/delete_task`, {task_id: taskId}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(() => {
      // Remove the task from the state
      const updatedTasks = tasks.filter(task => task.id !== taskId);
      setTasks(updatedTasks);
      message.success('Task deleted successfully');
    })
    .catch(error => {
      // Handle error
      message.error('Something went wrong, please try again later');
    });
  }; 

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>My Tasks</h1>
      <Table
        dataSource={tasks}
        columns={columns}
        loading={loading}
        style={{ width: '90%', margin: '0 auto' }}
        rowKey="id"
        locale={{ emptyText: <Empty description="No tasks available yet" /> }}
      />
      <Modal
        title={modalTitle}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
      >
        <List
          size="small"
          dataSource={modalContent}
          renderItem={item => <List.Item>{item}</List.Item>}
        />
      </Modal>
      <Modal
        title="Are you sure you want to edit this task?"
        onCancel={() => setIsEditModalVisible(false)}
        okText="Edit"
        onOk={handleEdit}
        open={isEditModalVisible}>
          It's advisable to avoid this approach if the task has already been administered to some participants.
      </Modal>

    </div>
  );
};

export default MyQiatTasks;