import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthUser } from "./AuthUserContext";

const PrivateRoute = ({ children }) => {
  const { user, isLoading } = useAuthUser();

  if (isLoading) {
    return <div>Loading...</div>; // Or any other loading indicator
}

return user ? children : <Navigate to="/login" />;
};

export default PrivateRoute;