import React from 'react';
import { Button, Typography, Divider, List, Steps } from 'antd';
import { DownloadOutlined, ArrowLeftOutlined } from '@ant-design/icons';

const { Title, Paragraph, Text, Link } = Typography;
const { Step } = Steps;

const RInstructionsPage = () => {
  return (
    <div style={{ padding: '24px', maxWidth: '800px', margin: '0 auto' }}>
      <Title level={2}>Instructions for Analyzing qIAT Data Using R</Title>
      <Paragraph>
        Follow the steps below to download and utilize the R scripts for analyzing your qIAT data. The process involves downloading a zip file containing the necessary R scripts, extracting the contents, and running the analysis using R on your local machine.
      </Paragraph>

      <Divider />

      <Steps direction="vertical" current={-1}>
        <Step
          title="Step 1: Download the R Scripts"
          description={
            <Paragraph>
              Click the button below to download a zip file containing the R scripts required for the analysis. This includes:
              <List>
                <List.Item><Text code>main.R</Text>: The main script to execute the analysis.</List.Item>
                <List.Item><Text code>analyze.R</Text>: Contains the analysis functions.</List.Item>
                <List.Item><Text code>parse.R</Text>: Contains the parsing functions.</List.Item>
              </List>
              <Button
                type="primary"
                icon={<DownloadOutlined />}
                onClick={() => {
                    window.open("https://pcplab.sfo2.digitaloceanspaces.com/portal_assets/qIAT-analyzing.zip", "_blank");
                }}
                >
                Download R Scripts
                </Button>

            </Paragraph>
          }
        />
        <Step
          title="Step 2: Extract the Downloaded Zip File"
          description={
            <Paragraph>
              After downloading the zip file, locate it in your downloads folder (or the folder you specified) and extract the contents to a directory on your computer.
            </Paragraph>
          }
        />
        <Step
          title="Step 3: Prepare Your Data"
          description={
            <Paragraph>
              Ensure that your qIAT data is saved as a CSV file. The CSV file should contain at least two columns:
              <List>
                <List.Item><Text code>ResponseID</Text>: The column that contains participant response IDs.</List.Item>
                <List.Item><Text code>qIAT</Text>: The column that contains the qIAT data.</List.Item>
              </List>
              Ensure the column names in your CSV file match exactly with the ones specified.
            </Paragraph>
          }
        />
        <Step
          title="Step 4: Modify the R Script (if needed)"
          description={
            <Paragraph>
              Open the <Text code>main.R</Text> script in an R editor (like RStudio). Locate and review the example usage of the <Text code>process_qiat_data</Text> function, which is found near the end of the <Text code>main.R</Text> script. You may need to modify the following parameters to match your dataset:
              <List>
                <List.Item><Text code>file_path</Text>: Update this to the path where your CSV file is stored.</List.Item>
                <List.Item><Text code>response_id_column</Text> and <Text code>qiat_data_column</Text>: Ensure these match the actual column names in your data.</List.Item>
                <List.Item><Text code>results_files_prefix</Text>: Choose a prefix for the output results files.</List.Item>
              </List>
              You can also adjust the advanced settings like latency exclusions if needed.
            </Paragraph>
          }
        />
        <Step
          title="Step 5: Run the Analysis"
          description={
            <Paragraph>
              Run the <Text code>main.R</Text> script in your R environment. The script will read your data, process it, and generate two results files:
              <List>
                <List.Item><Text code>your_results_prefix-participants.csv</Text>: Contains participant-level results.</List.Item>
                <List.Item><Text code>your_results_prefix-trials.csv</Text>: Contains trial-level results.</List.Item>
              </List>
              These files will be saved in the same directory where you extracted the R scripts.
            </Paragraph>
          }
        />
        <Step
          title="Step 6: Review the Results"
          description={
            <Paragraph>
              Open the generated CSV files to review the analyzed results. The participants file will give you an overview of participant-level data, while the trials file will provide detailed trial-level analysis.
            </Paragraph>
          }
        />
      </Steps>

      <Divider />

      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Button type="default" icon={<ArrowLeftOutlined />} onClick={() => window.history.back()}>
          Back to Analysis Page
        </Button>
      </div>
    </div>
  );
};

export default RInstructionsPage;
