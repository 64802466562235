// src/App.js
import React from "react";
import { ThemeProvider } from "styled-components";
import styled from 'styled-components';
import { ColorField } from "./styles/themes";
import AppRoutes from "./routes/AppRoutes";
import Navbar from "./components/Navbar";
import { AuthUserProvider } from "./components/AuthUserContext";
import { GlobalStyle } from './styles/shared_components';

const Container = styled.div`
  margin-left: 10%;
  margin-right: 10%;
  background-color: ${({ theme }) => theme.cont}; // Adjust as needed
  min-height: 100vh; // Ensures the container takes at least the full height of the viewport
  padding: 1rem; // Optional, for some spacing inside
`;

const Background = styled.div`
  background-color: ${({ theme }) => theme.sides}; // Adjust as needed
  min-height: 100vh; // Ensures background color spans the full height of the viewport
`;

function App() {
  return (
    <AuthUserProvider>
      <ThemeProvider theme={ColorField}>
          <Background>
            <Navbar />
            <GlobalStyle />
            <Container>
              <AppRoutes />
            </Container>
          </Background>
      </ThemeProvider>
    </AuthUserProvider>
  );
}

export default App;
