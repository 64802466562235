import React, { useState } from 'react';
import { Typography, Button, Image, Input, message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, CopyOutlined } from '@ant-design/icons';
import { ImageContainer, FakeLink} from "../styles/shared_components";
import styled from 'styled-components';

const { Title, Paragraph } = Typography;

const CodeBlock = styled.pre`
  background-color: #f5f5f5;
  padding: 16px;
  border-radius: 4px;
  overflow: auto;
  line-height: 1.5;
`;

const GoBackButton = styled(Button)`
  margin-top: 5%;
`;

const CopyLink = ({ url }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
      message.success('Link copied!');
    } catch (err) {
      message.error('Failed to copy!');
    }
  };

  return (
    <span style={{display: 'inline-flex', alignItems: 'center'}}>
      <Input 
      value={url} 
      readOnly
      style={{ width: 'auto', marginRight: 8, maxWidth: '60%', minWidth: '340px', }}
      />
      <Button
        icon={<CopyOutlined />}
        onClick={() => copyToClipboard(url)}
      >
        Copy Link
      </Button>
      {copySuccess}
    </span>
  );
};


class TaskIntegrationInstructionsClass extends React.Component {
  componentDidMount() {
    console.log("Task data received:", this.props.task);
  }

  constructor(props) {
    super(props);
  }

  renderQualtricsInstructions(task) {    
    const integrationCode = `
Qualtrics.SurveyEngine.addOnload(function(){
  var url = '${task.url}'; 
  var self = this;
  var container = self.getQuestionContainer();
  
  container.querySelector('.Inner').style.display = 'none';
  document.querySelector('#SkinContent').style.background = '#3d3e3f';
  self.hideNextButton();
  
  var scriptTag = document.createElement('script');
  scriptTag.src = 'https://cdn.jsdelivr.net/gh/minnojs/minno-quest@0.3/dist/pi-minno.js';

  scriptTag.onload = onLoad;
  scriptTag.onreadystatechange = onLoad;
  container.appendChild(scriptTag);
  
  var canvas = document.createElement('div');
  container.appendChild(canvas);
  
  function onLoad(){
    minnoJS(canvas, url);
    minnoJS.logger = function(value){
      var el = container.querySelector('textarea');
      el.value = value;
    }		
    minnoJS.onEnd = function(){ setTimeout(self.clickNextButton.bind(self), 100); }
  }
});

Qualtrics.SurveyEngine.addOnReady(function(){});

Qualtrics.SurveyEngine.addOnUnload(function(){});
    `.trim();

    return (
      <>
        <Paragraph>
          <b>A.</b> Enter the "Survey" in Qualtrics in which you wish to add the new qIAT task.
        </Paragraph>
        <Paragraph>
        <b>B.</b> It is recommended to add a new "Block" in the Qualtrics survey, although it is not mandatory.
        </Paragraph>
        <Paragraph>
        <b>C.</b> Within the chosen Qualertics "Block", add a new "Question", with a 'Text entry' type. <b>Important!</b> Please make a note of the "Question" name (e.g., 'qIAT'), as it will be essential for the qIAT task analysis.
        </Paragraph>
        <Paragraph>
        <b>D.</b> Set "Multiple Lines" as the 'Text type' attribute for the "Question".
        </Paragraph>
        <Paragraph>
        <b>E.</b> Ensure the "Question" is empty, containing no text or other elements.
        </Paragraph>
        <Paragraph>
        <b>F.</b> In the 'Edit question' sidebar, navigate to 'Question behavior' and click on 'Javascript'.
        </Paragraph>
        <Paragraph>
        <b>G.</b> Replace all existing text with the following script:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(<b>example</b>:&nbsp;
        <Image
            src="https://cdn-icons-png.flaticon.com/512/13/13706.png"
            alt="Qualtrics JS Example"
            width={20}
            preview= {{
              src: "https://pcplab.sfo2.digitaloceanspaces.com/Roee/qualetrics_js.jpg"
            }}
          />)
        </Paragraph>
        <CodeBlock>
          <code className="language-javascript">{integrationCode}</code>
        </CodeBlock>
        <Paragraph>
        <b>H.</b> Now you can test and run your experiment!
        </Paragraph>
      </>
    );
  }

  renderOtherInstructions(task) {
    const taskUrl = task.url;
    const exampleUrl = taskUrl + '?pid=SUBJECT_IDENTIFIER';
    const instructions = <p><b>1) </b>Ensure that the 'pid' parameter is consistently passed from pre-task components to the task <u>using the exact name 'pid'</u>. This maintains uniformity and ensures the parameter is correctly captured across all stages of the study.<br/ ><b>2) </b>To direct participants to your qIAT task after they complete the pre-task component, Use the Task Url as the completion URL on your survey platform (examples from Pavlovia and QuestionPro below) or recruitment platform (example from Prolific below) to start the qIAT task immediately upon participant recruitment.<br /><b>3) </b>Alternatively, manually open the qIAT task by entering The task URL in your browser when running in a controlled setting like a lab. Append the participant ID as a URL parameter (pid) for proper session tracking. For example: <Input value={exampleUrl} readOnly style={{width: '51%'}}/> (you can replace SUBJECT_IDENTIFIER with whatever you want).<br /><b>4) </b>If you run the task URL without specifying a 'pid' URL parameter, the system will automatically assign a 'pid' value of 0. This helps easily differentiate between testing the task and executing it under normal conditions.</p>;
    return (
      <>
      <p>Task URL: &nbsp;<CopyLink url={taskUrl} /></p>
       <p><h3>Linking the Task With Pre-Task Components</h3></p>
    {instructions}
    <p><u>Example (Pavlovia)</u>:</p>
    <ImageContainer>
    <Image
    src="https://pcplab.sfo2.digitaloceanspaces.com/portal_assets/pre_pavlovia.jpg"
    width={'400px'}
    />
    </ImageContainer>
    <p><u>Example (QuestionPro)</u>:</p>
    <ImageContainer>
    <Image
    src="https://pcplab.sfo2.digitaloceanspaces.com/portal_assets/pre_questiopro.jpg"
    width={'400px'}
    />
    </ImageContainer>
    <p><u>Example (Prolific)</u>:</p>
    <ImageContainer>
    <Image
    src="https://pcplab.sfo2.digitaloceanspaces.com/portal_assets/pre_prolific.jpg"
    width={'400px'}
    />
    </ImageContainer>
      </>
    );
  }

  render() {
    const { task, navigate } = this.props;

    return (
      <div>
        <Title level={2} style={{ textAlign: 'center' }}>Integrate '{task.name}' Task In Your Study</Title>
        {task.platform === 'qualtrics' ? this.renderQualtricsInstructions(task) : this.renderOtherInstructions(task)}
        <GoBackButton 
          icon={<ArrowLeftOutlined />} 
          shape="round" 
          onClick={() => navigate('/my-tasks')}
          type='primary'
        />
      </div>
    );
  }
}

const TaskIntegrationInstructions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const task = location.state.task; // Get the task data passed in the state

  return <TaskIntegrationInstructionsClass task={task} navigate={navigate} />;
};

export default TaskIntegrationInstructions;
