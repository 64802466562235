// src/routes/AppRoutes.js
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import TaskCreationPage from "../pages/TaskCreationPage";
import AnalysisPage from "../pages/AnalysisPage";
import Login from "../pages/LoginPage";
import Signup from "../pages/SignUpPage";
import MyQiatTasks from "../pages/MyQIATTasks";
import PrivateRoute from "../components/PrivateRoute";
import EmailVerificationPage from "../pages/EmailVerificationPage";
import PasswordReset from "../pages/PasswordReset";
import PasswordResetRequest from "../pages/PasswordResetRequest";
import TaskIntegrationInstructions from "../pages/TaskIntegrationInstructions";
import AccountSettingsPage from "../pages/AccountSettingsPage";
import RInstructionsPage from "../pages/RInstructionsPage";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/new-task" element={<PrivateRoute><TaskCreationPage /></PrivateRoute>} />
      <Route path="/analysis" element={<PrivateRoute><AnalysisPage /></PrivateRoute>} />
      <Route path="/my-tasks" element={<PrivateRoute><MyQiatTasks /></PrivateRoute>} /> {/* Protected route */}
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/verify" element={<EmailVerificationPage />} />
      <Route path='/reset-password' element={<PasswordResetRequest />}/>
      <Route path="/new-password" element={<PasswordReset />}/>
      <Route path="/my-tasks/instructions" element= {<TaskIntegrationInstructions />}/> 
      <Route path="/account-settings" element= {<PrivateRoute><AccountSettingsPage /></PrivateRoute>} />
      <Route path="/r-instructions" element= {<RInstructionsPage />}/>

      
      {/* Catch-all route */}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default AppRoutes;
