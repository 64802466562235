import React from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { useAuthUser } from './AuthUserContext'; // Ensure you have created this context
import styled, { createGlobalStyle } from "styled-components";
import { Dropdown, Menu, Space, message } from 'antd';
import { LoginOutlined, LogoutOutlined, SettingOutlined, HomeOutlined, UserAddOutlined, UserOutlined, BarChartOutlined, FormOutlined, UnorderedListOutlined, LineChartOutlined} from '@ant-design/icons';

const Nav = styled.nav`
  background: ${({ theme }) => theme.navbar}; // Adjust as needed
  padding: 1rem;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
`;

const NavLinkContainer = styled.div`
  margin-left: 10%;
`;

const UserContainer = styled.div`
  margin-right: 10%;
  color: #ffa; 
`;

const NavBarLink = styled(NavLink)`
  margin: 0 1.5rem;
  color: #fff;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;

  // &:hover {
  //   text-decoration: underline;
  // }
`;

const NavLinkfirst = styled(NavBarLink)`
  margin-left: 0;
`;


const Navbar = () => {
  const { user, removeTokenUser } = useAuthUser(); // Assuming there's a logout function in your context
  const navigate = useNavigate();

  const handleMenuClick = (e) => {
    if (e.key === 'logout') {
      // Call logout function
      removeTokenUser();
      navigate('/')
    } else if (e.key === 'settings') {
      // Navigate to the user settings page
      navigate('/account-settings');
    }
  };

  const items = [
    {
      label: (
        <Space>
          <SettingOutlined />
          Account Settings
        </Space>
      ),
      key: 'settings',
      onClick: handleMenuClick
    },
    {
      label: (
        <Space>
          <LogoutOutlined />
          Logout
        </Space>
      ),
      key: 'logout',
      onClick: handleMenuClick
    }
  ];

  const redirectToLogin = () => {
    message.warning("Please login to view this page.");
    navigate('/login');
  };

  return (
    <Nav>
      <NavLinkContainer>
        <NavLinkfirst to="/"><HomeOutlined /> Home</NavLinkfirst>
          <NavBarLink to="/my-tasks" onClick={(e) => {
            if (!user) {
              e.preventDefault(); // Prevent the link from navigating
              redirectToLogin(); // Call the redirect function
         }
        }}>
          <UnorderedListOutlined /> My Tasks
         </NavBarLink>
         <NavBarLink to="/new-task" onClick={(e) => {
            if (!user) {
              e.preventDefault(); // Prevent the link from navigating
              redirectToLogin(); // Call the redirect function
         }
        }}>
          <FormOutlined /> New Task
        </NavBarLink>
        <NavBarLink to="/analysis" onClick={(e) => {
             if (!user) {
               e.preventDefault(); // Prevent the link from navigating
               redirectToLogin(); // Call the redirect function
            }
        }}>
        <BarChartOutlined /> Data Analysis
      </NavBarLink>
            {/* <NavBarLink to="/analysis-guide">Analysis Guide</NavBarLink> */}
      </NavLinkContainer>
      <UserContainer>
        {user ? (
          <Dropdown menu={{ items }} trigger={['hover']}>
            <a onClick={e => e.preventDefault()}>
              <Space>
                <UserOutlined />
                {user.full_name}
              </Space>
            </a>
          </Dropdown>
        ) : 
        <>
          <NavBarLink to="/login"><LoginOutlined /> Login</NavBarLink>
          <NavBarLink to="/signup"><UserAddOutlined /> Signup</NavBarLink>
        </>
        }
      </UserContainer>
    </Nav>
  );
};

export default Navbar;