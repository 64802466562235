import styled from 'styled-components';
import { InfoCircleTwoTone } from '@ant-design/icons';

import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  .ant-typography {
    font-size: 17px; // Your desired global font size for paragraphs
    font-family: 'Montserrat', sans-serif;
    // Add other styles as needed
  }
`;

export const StyledHeader = styled.h1`
  text-align: center;
`;

export const StyledParagraph = styled.p`
color: grey;
text-align: left;
`;

export const QuestionMarkIcon = styled(InfoCircleTwoTone)`
  margin-left: 8px;
  font-size: 16px;
  cursor: pointer;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  /* Add some space between all elements inside */
  > * {
    margin-right: 20%;
  }
  align-items: center;
  margin-top: 20px;
`;
export const ButtonsContainer2 = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const ImageContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
`;

export const FakeLink = styled.span`
color: #0645ad;
text-decoration: underline;
cursor: pointer;
`;