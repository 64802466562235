import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Form, Input, Button, Alert } from 'antd';
import { LockFilled } from '@ant-design/icons';
import axios from 'axios';
import DEV_REQUESTS_PREFIX from '../utils/config';



const PasswordReset = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  // Custom validator for confirm password
  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('new_password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('The two passwords that you entered do not match!'));
    },
  });

  const onFinish = async (values) => {
    try {
      const token = searchParams.get('token');
      await axios.post(`${DEV_REQUESTS_PREFIX}/api/reset-password`, { new_password: values.new_password, token });
      setSuccess(true);
      setTimeout(() => { navigate('/login'); }, 3000);
    } catch (e) {
      setError('Failed to reset password. The link may be expired or invalid.');
    }
  };

  return (
    <div>
      {error && <Alert message={error} type="error" showIcon />}
      {success && <Alert message="Password reset successfully! Redirecting to login page." type="success" showIcon />}
      <Form
        style={{ textAlign: 'center' }}
        name="password_reset"
        onFinish={onFinish}
      >
        <h2>Reset Password</h2>
        <Form.Item
          name="new_password"
          rules={[{ required: true, message: 'Please input your new Password!' }]}
        >
          <Input
            style={{ width: '40%' }}
            prefix={<LockFilled />}
            type="password"
            placeholder="Enter New Password"
          />
        </Form.Item>
        <Form.Item
          name="confirm_password"
          dependencies={['new_password']}
          hasFeedback
          rules={[
            { required: true, message: 'Please confirm your new password!' },
            validateConfirmPassword
          ]}
        >
          <Input
            type="password"
            placeholder="Confirm New Password"
            prefix={<LockFilled />}
            style={{ width: '40%' }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Reset Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordReset;
