import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import DEV_REQUESTS_PREFIX from '../utils/config';

const ACCESS_TOKEN_EXPIRE_MINUTES = 30;

export function isTokenExpired() {
    const tokenIssuedAt = localStorage.getItem('tokenIssuedAt');
    if (!tokenIssuedAt) return true;

    const expiryTime = new Date(parseInt(tokenIssuedAt)).getTime() + (ACCESS_TOKEN_EXPIRE_MINUTES * 60000);
    const currentTime = new Date().getTime();

    return currentTime > expiryTime;
}

const AuthUserContext = createContext();

export const useAuthUser = () => {
  const context = useContext(AuthUserContext);
  return {
      ...context,
      updateUser: context.updateUser
  };
};

export const AuthUserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem('token') || null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initUser = async () => {
      setIsLoading(true);
      const storedToken = localStorage.getItem('token');
      if (storedToken && !isTokenExpired()) {
        try {
          const response = await axios.get(`${DEV_REQUESTS_PREFIX}/api/user-info`, {
            headers: { 'Authorization': `Bearer ${storedToken}` }
          });
          setUser(response.data.user);
        } catch (error) {
          console.error('Error fetching user info:', error);
          removeTokenUser();
        }
      }
      setIsLoading(false);
    };

    initUser();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.post(`${DEV_REQUESTS_PREFIX}/api/token/refresh`, `refresh_token=${localStorage.getItem('refresh_token')}`, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
        console.log("Token refresh successful", response.data);
        addTokenUser(response.data.access_token, localStorage.getItem('refresh_token'), user);
    } catch (error) {
        console.error('Error refreshing token:', error);
        removeTokenUser();
        window.location.href = '/login';
    }
  };

  const addTokenUser = (newAccessToken, newRefreshToken, user) => {
    const currentTime = new Date().getTime();
    localStorage.setItem('token', newAccessToken);
    localStorage.setItem('refresh_token', newRefreshToken);
    localStorage.setItem('tokenIssuedAt', currentTime);
    setToken(newAccessToken);
    setUser(user);
  };

  const removeTokenUser = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    setToken(null);
    setUser(null);
  };

  axios.interceptors.request.use(
    async config => {
      if (config.url === `${DEV_REQUESTS_PREFIX}/api/token/refresh` || config.url === `${DEV_REQUESTS_PREFIX}/api/login` ) {
            return config;  // Skip interceptor logic for token refresh requests
        }
      if (isTokenExpired() && localStorage.getItem('refresh_token')) {
        console.log("Token is expired, refreshing...");
        await refreshToken();
        config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  const updateUser = (updatedUserData) => {
    setUser((currentUser) => ({ ...currentUser, ...updatedUserData }));
};  

  return (
    <AuthUserContext.Provider value={{ user, token, isLoading, addTokenUser, removeTokenUser, updateUser }}>
      {children}
    </AuthUserContext.Provider>
  );
};
