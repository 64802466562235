import React, { useState } from 'react';
import { Form, Input, Button, Alert } from 'antd';
import { MailFilled } from '@ant-design/icons';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { StyledParagraph } from '../styles/shared_components';
import DEV_REQUESTS_PREFIX from '../utils/config';


const PasswordResetRequest = () => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const onFinish = async (values) => {
    try {
      await axios.post(`${DEV_REQUESTS_PREFIX}/api/request-password-reset`, values);
      setSuccess(true);
    } catch (e) {
      if (e.response && e.response.status === 400) {
        setError(<span>
          Email does not exist. <Link to="/signup">Sign up?</Link>
        </span>);
      } else {
      setError('An error occurred. Please try again later.');
      }
    }
  };

  return (
    <div>
      {error && <Alert message={error} type="error" showIcon />}
      {success && <Alert message="If an account with this email exists, a password reset email has been sent." type="success" showIcon />}
      <Form
        style={{ textAlign: 'center' }}
        name="password_reset_request"
        onFinish={onFinish}
      >
        <h2>Reset Password</h2>
        <StyledParagraph>Send a password reset link to your email.</StyledParagraph>
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Please input your Email!' }]}
        >
          <Input 
            style={{ width: '40%' }}
            prefix= {<MailFilled />}
            placeholder="Email" 
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Request Password Reset
          </Button>
        </Form.Item>
        <Form.Item>
          Or <Link to="/login">login now!</Link>
        </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordResetRequest;
