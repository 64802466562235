import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Alert } from 'antd';
import DEV_REQUESTS_PREFIX from '../utils/config';


const EmailVerificationPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState('');

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      verifyEmail(token);
    }
  }, [searchParams]);

  const verifyEmail = async (token) => {
    try {
      await axios.get(`${DEV_REQUESTS_PREFIX}/api/verify_email?token=${token}`);
      setVerificationStatus('success');
      setTimeout(() => { navigate('/login'); }, 3000);
    } catch (error) {
      setVerificationStatus('error');
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      {verificationStatus === 'success' && <Alert message="Email verified successfully! Redirecting to login." type="success" />}
      {verificationStatus === 'error' && <Alert message="Email verification failed. Please try again or contact support." type="error" />}
    </div>
  );
};

export default EmailVerificationPage;
