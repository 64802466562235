import React, { useState } from 'react';
import { Form, Input, Button, Alert, Spin } from 'antd';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { MailFilled, LockFilled, UserOutlined, BankFilled } from '@ant-design/icons';
import styled from 'styled-components';
import DEV_REQUESTS_PREFIX from '../utils/config';


const AnalysisPageOverlay = styled.div`
  display: ${({ loading }) => (loading ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 999;
`;

const SignUpPage = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [formProcessing, setFormProcessing] = useState(false);

  const onFinish = async (values) => {
    try {
      setError(null);
      setFormProcessing(true);
      const response = await axios.post(`${DEV_REQUESTS_PREFIX}/api/signup`, values);
      if (response.data.success) {
        setSuccess(true);
        setTimeout(() => {navigate('/');}, 4000)
        
      }
      setFormProcessing(false);
    } catch (e) {
      if (e.response && e.response.status === 409) {
        setError(<span>
          Email is already taken.  
          <Link to="/login">  Log in instead?</Link>
        </span>);
      } else {
        setError('An error occurred. Please try again.');
      }
      setFormProcessing(false);
    }
  };

  // Custom validator for confirm password
  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('The two passwords that you entered do not match!'));
    },
  });

  return (
    <Spin spinning={formProcessing} /*indicator={antIcon}*/ size="large" tip="Signing up...">
      <AnalysisPageOverlay loading={formProcessing}/>
        <div className="signup-container">
          {error && <Alert message={error} type="error" showIcon />}
          {success && (
  <Alert
    message={
      <>
        Successfully registered! Please check your email to verify your account.
        <br />
        If you do not see the verification email, please check your spam folder.
      </>
    }
    type="success"
    showIcon
  />
)}
          <Form
            style={{textAlign: 'center'}}
            name="normal_signup"
            className="signup-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            <h2>Sign Up</h2>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input your Email!' }]}
            >
              <Input 
              placeholder="Email"
              style={{ width: '40%' }}
              prefix= {<MailFilled />} />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your Password!' }]}
            >
              <Input
                type="password" 
                placeholder="Password"
                prefix={<LockFilled/>}
                style={{ width: '40%' }} />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              dependencies={['password']}
              hasFeedback
              rules={[
                { required: true, message: 'Please confirm your password!' },
                validateConfirmPassword
              ]}
            >
              <Input
                type="password"
                placeholder="Confirm Password"
                prefix={<LockFilled/>}
                style={{ width: '40%' }} />
            </Form.Item>
            <Form.Item
              name="full_name"
              rules={[{ required: true, message: 'Please input your Full Name!' }]}
            >
              <Input 
              placeholder="Full Name"
              style={{ width: '40%' }}
              prefix={<UserOutlined/>} />
            </Form.Item>
            <Form.Item
              name="institution"
              rules={[{ required: true, message: 'Please input your Institution!' }]}
            >
              <Input 
              placeholder="Institution/Company"
              style={{ width: '40%' }}
              prefix={<BankFilled/>} />
            </Form.Item>
            <Form.Item>
              <p style={{ width: '40%', margin: '0 auto', textAlign: 'center' }}>
              After signing up, you will be required to verify the email address provided here. Please check your spam folder if you do not see the verification email.
              </p>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="signup-form-button">
                Sign Up
              </Button>
            </Form.Item>
            <Form.Item>
            Already have an account?<br /><Link to="/login">login now!</Link>
            </Form.Item>
          </Form>
        </div>
    </Spin>
  );
};

export default SignUpPage;
